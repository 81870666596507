import { StoreStatus } from "~/layers/admin/modules/auth/runtime/types/api";

export const useAccountStatus = () => {
  const auth = useAuth();

  const accountStatus = computed(() => auth.context.value?.workspace?.status);
  const status = computed(() => auth.context.value?.workspace?.status?.status);
  const pending = computed(() => status.value === StoreStatus.PENDING);
  const pendingApproval = computed(
    () => status.value === StoreStatus.PENDING_APPROVAL
  );
  const rejected = computed(() => status.value === StoreStatus.REJECTED);
  const suspended = computed(() => status.value === StoreStatus.SUSPENDED);

  const isTrial = computed<boolean>(
    () => (!accountStatus.value?.plan && accountStatus.value?.trial) || false
  );
  const pendingContract = computed(
    () => !!accountStatus.value?.plan && accountStatus.value?.trial
  );
  const trialDaysLeft = computed(
    () => accountStatus.value?.trial_days_left || 0
  );
  const hasContract = computed(
    () => accountStatus.value?.has_contract || false
  );
  const nextSteps = computed(() => accountStatus.value?.next_steps || []);

  return {
    status,
    pending,
    pendingApproval,
    rejected,
    suspended,
    isTrial,
    pendingContract,
    trialDaysLeft,
    hasContract,
    nextSteps,
  };
};
